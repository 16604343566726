import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { RememberMe, Routes } from "./components";
import { UserProvider } from "./contexts/UserContext";
import "./index.css";
import "./lib/axios";

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Router>
        <UserProvider>
          <RememberMe>
            <Routes />
          </RememberMe>
        </UserProvider>
      </Router>
    </>
  );
};

export default App;
