import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios, { AxiosResponse } from "axios";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import { Dictionary } from "lodash";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useState } from "react";
import { useDevice } from "../hooks";
import { Measurement, MeasurementValue, SensorResult } from "../types";
import {
  DataInterval,
  formatDate,
  formatDateTime,
  formatMeasurementValue,
  formatSensorName,
  INTERVALS,
  MeasurementsDict,
  sortMeasurementsByDate,
} from "../util";
import SensorCard from "./SensorCard";
import WeightGraph from "./WeightGraph";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: 330,
    [theme.breakpoints.up("sm")]: {
      width: 730,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const TABLE_SENSOR_KEYS = ["weight", "temp_load", "humidity", "battery"];
const AVAILABLE_SENSOR_KEYS = [...TABLE_SENSOR_KEYS, "wifi"];

const Device: React.FC<{ deviceId: number }> = ({ deviceId }) => {
  const classes = useStyles();

  const [{ loading, data }] = useDevice(deviceId, { expanded: 1 });

  const [measurementsLoading, setMeasurementsLoading] = useState(true);
  const [measurements, setMeasurements] = useState<MeasurementsDict>({});

  const [selectedInterval, setSelectedInterval] = useState<DataInterval>(INTERVALS[0]);

  useEffect(() => {
    async function query(): Promise<void> {
      if (data) {
        setMeasurementsLoading(true);
        const sensorData = await Promise.all(
          AVAILABLE_SENSOR_KEYS.reduce<Array<Promise<AxiosResponse<SensorResult>>>>((acc, key) => {
            if (data.sensors.find((sensor) => sensor.key === key)) {
              acc.push(
                axios.get<SensorResult>(`/device/${deviceId}/sensor/${key}/measurements/series`, {
                  params: {
                    date_from: formatDate(
                      subMonths(subDays(new Date(), selectedInterval.days), selectedInterval.month),
                    ),
                    date_to: formatDate(new Date()),
                  },
                }),
              );
            }
            return acc;
          }, []),
        );
        const sm = sensorData.reduce<MeasurementsDict>((acc, curr, i) => {
          acc[AVAILABLE_SENSOR_KEYS[i]] = sortMeasurementsByDate(
            uniqBy(curr.data.data, ([date]) => date),
            -1,
          );
          return acc;
        }, {});
        setMeasurements(sm);
        setMeasurementsLoading(false);
      }
    }
    void query();
  }, [deviceId, data, selectedInterval]);

  if (loading || measurementsLoading || !data) return <CircularProgress />;

  const { name, description, serialNumber, sensors } = data;

  const tableHeader = TABLE_SENSOR_KEYS.reduce<Dictionary<string>>((acc, key) => {
    const sensor = sensors.find((s) => s.key === key);
    if (sensor) {
      acc[sensor.id] = `${formatSensorName(sensor.name)} (${sensor.dataType.display.unit})`;
    }
    return acc;
  }, {});
  const tableRows = Object.entries(measurements).reduce<Dictionary<MeasurementValue[]>>(
    (acc, [key, currentMeasurements]) => {
      currentMeasurements.forEach(([date, value]) => {
        if (TABLE_SENSOR_KEYS.includes(key)) {
          if (acc[date] as MeasurementValue[] | undefined) {
            acc[date].push(value);
          } else {
            acc[date] = [value];
          }
        }
      });
      return acc;
    },
    {},
  );

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
      <Grid item>
        <Typography variant="h4">{name}</Typography>
        <Typography variant="h5">{description}</Typography>
        <Typography variant="h6">{serialNumber}</Typography>
        {/* <Typography variant="h4">{hardwareVersion}</Typography> */}
      </Grid>

      <Grid item>
        <WeightGraph
          measurements={measurements.weight}
          interval={selectedInterval}
          setDataInterval={setSelectedInterval}
        />
      </Grid>

      <Grid item>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
          {AVAILABLE_SENSOR_KEYS.map((key) => {
            const sensor = sensors.find((s) => s.key === key);
            if (sensor) {
              const lastMeasurement = measurements[sensor.key][0] as Measurement | undefined;
              if (lastMeasurement) {
                const [lastDate, lastValue] = lastMeasurement;
                return (
                  <Grid key={sensor.id} item>
                    <SensorCard
                      sensor={sensor}
                      date={lastDate}
                      value={formatMeasurementValue(lastValue)}
                    />
                  </Grid>
                );
              }
            }
            return undefined;
          })}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table style={{ minWidth: 650 }} size="small" aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                {Object.values(tableHeader).map((heading) => (
                  <TableCell key={heading} align="right">
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(tableRows).map(([date, values]) => (
                <TableRow key={date}>
                  <TableCell component="th" scope="row">
                    {formatDateTime(date)}
                  </TableCell>
                  {values.map((value, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell key={i} align="right">
                      {formatMeasurementValue(value)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Device;
