import { Container } from "@material-ui/core";
import React from "react";
import Header from "./Header";

const Page: React.FC<{ children: any }> = ({ children }) => {
  return (
    <>
      <Header />
      <Container>{children}</Container>
    </>
  );
};

export default Page;
