import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useHive } from "../hooks";
import Device from "./Device";

const Hive: React.FC = () => {
  const { hiveId } = useParams<{ hiveId: string }>();
  const [{ loading, data }] = useHive(Number.parseInt(hiveId, 10), { expanded: 1 });

  if (loading || !data) return <CircularProgress />;

  const { name, description, devices } = data;
  const constantDevice = devices[0];

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center" spacing={3}>
      <Grid item>
        <Typography variant="h2">{name}</Typography>
        <Typography variant="h3">{description}</Typography>
      </Grid>

      <Grid item>
        <Device deviceId={constantDevice.id} />
      </Grid>
    </Grid>
  );
};

export default Hive;
